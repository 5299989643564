<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card
      v-for="t in teams"
      :key="t._id"
    >
      <v-card-text>
        <h2>{{ t.name }}</h2>
        <v-data-table
          :items="t.acl"
          :headers="[
            { text: 'Funktion', value: 'type', sortable: false },
            { text: 'Person', value: 'person', sortable: false },
            { text: 'E-Mail', value: 'email', sortable: false }
          ]"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #item.type="{item}">
            <span v-if="item.type === 'team'">Mannchaftsverantwortlicher</span>
            <span v-if="item.type === 'club'">Vereinsverantwortlicher</span>
          </template>
          <template #item.person="{item}">
            {{ item | person }}
          </template>
          <template #item.email="{item}">
            <span v-if="item.email" v-html="item.email.join('<br>')" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <h2>Begegnungen:</h2>
        <v-data-table
          :items="eventjudges"
          :headers="[
            { text: 'Begegnung am', value: 'startDate', sortable: false },
            { text: 'Gegner', value: 'gegner', sortable: false },
            { text: 'Typ', value: 'type', sortable: false },
            { text: 'Kampfrichter', value: 'name', sortable: false },
            { text: 'E-Mail', value: 'mail', sortable: false }
          ]"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #item.startDate="{item}">{{ item.startDate | dateformat('DD.MM.YYYY HH:mm') }}</template>
          <template #item.gegner="{item}"><span v-html="item.teams.filter(t => t.team._id !== team).map(t => t.team.name).join('<br />')" /></template>
          <template #item.name="{item}">
            <template v-if="item.judge.person">{{ item.judge.person | person }}<br><span v-if="item.judge.club" style="font-size:70%;font-style:italic;">(Verein: {{ item.judge.club.name }})</span></template>
            <template v-else-if="item.judge.club">Vereinseinsatz:<br>{{ item.judge.club.name }}</template>
          </template>
          <template #item.type="{item}">
            <template v-if="item.judge.type === 'e1'">Oberkampfrichter E1</template>
            <template v-if="item.judge.type === 'e2'">Neutraler Kampfrichter E2</template>
            <template v-if="item.judge.type === 'e3'">Heimkampfrichter E3</template>
            <template v-if="item.judge.type === 'e4'">Gastkampfrichter E4</template>
            <template v-if="item.judge.type === 'dh'">Heimkampfrichter D</template>
            <template v-if="item.judge.type === 'dg'">Gastkampfrichter D</template>
          </template>
          <template #item.mail="{item}">
            <template v-if="item.email && item.email.length > 0"><span v-html="item.email.map(m => m.value).join('<br />')" /></template>
            <template v-else-if="item.judge.type">
              <span style="font-size:70%;font-style:italic;">Vereinsverantwortlicher:</span><br>
              <span v-html="item.clubmail.map(m => m.value).join('<br />')" />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'communication',

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    teams () {
      return this.eventteams.map(t => ({
        _team: t.team._team,
        name: t.team.name,
        acl: t.acl.map(p => ({
          familyName: p.familyName,
          givenName: p.givenName,
          email: p.email,
          type: p.type
        }))
      })).sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
    },
    eventteams () {
      if (this.EventFind.length < 1) return []

      return this.EventFind.reduce((acc, curr) => {
        acc.push(...(curr.teams || []))
        return acc
      }, [])
    },
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...(curr.events || []).filter(e => e.mode.indexOf('l') !== 0))
        return acc
      }, [])?.filter(e => !!e?.teams?.find(t => t._team === this.team))?.sort((a, b) => a.startDate < b.startDate ? -1 : 1)
    },
    eventjudges () {
      return this.events.reduce((acc, curr) => {
        const j = curr.judges?.filter(j => !!j && (!!j.person || !!j.club)) || []
        if (j.length === 0) acc.push({ ...curr, judges: undefined, judge: {}, email: [] })
        j.forEach(j => {
          acc.push({
            ...curr,
            judge: j,
            email: j?.person?.email || [],
            clubmail: j?.club?.acl?.reduce((a, c) => { a.push(...(c.email?.map(e => ({ ...e, p: c })) || [])); return a }, []) || []
          })
        })
        return acc
      }, [])
    }
  },

  apollo: {
    EventFind: {
      query: gql`
        query($parent: UUID!, $team: UUID!) { EventFind(parent: $parent, team: $team) {
          _id
          ... on StbM2021League {
            teams {
              _team
              team { _id name }
              acl(gender: m) {
                _person
                familyName givenName
                email
                type
              }
            }
            events {
              _id
              startDate
              mode
              teams { _team team { _id name } }
              judges {
                type
                person { _id familyName givenName email { type value }}
                club { _id name acl(gender: m) { _id familyName givenName email { type value }}}
              }
            }
          }
        }}`,
      variables () {
        return {
          parent: this.id,
          team: this.team
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
